import axios from "axios";
import {getRecords} from "../reducers/userDataReducer";
import {serverURL} from "../config";

export function records() {
    return async dispatch => {
        try {
            const response = await axios.get(`${serverURL}/api/records/records`,
                {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}})


            dispatch(getRecords(response.data))

        }
        catch (e) {
            //console.log(e.response.data.message())
        }

    }
}

export const setRecords = async (productivity, energy, noise) => {
    try {
        const response = await axios.post(`${serverURL}/api/records/add`, {
            productivity,
            energy,
            noise
        },{headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}})
        return response.data.message
    } catch (e) {
        alert(e.response.data.message)
    }
}

export const setCustomRecords = async (labels) => {
    try {
        const response = await axios.post(`${serverURL}/api/records/addCustom`, {
            labels
        },{headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}})
        return response.data.message
    } catch (e) {
        alert(e.response.data.message)
    }

}