import {setTheme, setCounter} from "../reducers/appearanceReducer";
import {setUser} from "../reducers/userReducer";

export const themeColor = (color) => {
    return dispatch => {
        dispatch(setTheme(color))
    }
}

export const counterSize = (size) => {
    return dispatch => {
        dispatch(setCounter(size))
    }
}
