import {useRef} from "react";

export default class FocusCounter {
    min;
    sec;


    // constructor(m, s) {
    //     this.min = m;
    //     this.sec = s;
    // }

    getMinutes () {
        return this.min
    }

    getSecond () {
        return this.sec
    }

    // Select Every Count Container
    countContainer = document.querySelectorAll(".count-digit");


// Select HTML5 Audio element
    timeoutAudio = document.getElementById("alarm_audio");

// Default inital value of timer
    defaultValue = 25 * 60;

    countDownTime = this.defaultValue;

// variable to store time interval
    timerID;

// Variable to track whether timer is running or not
    isStopped = true;

// Function calculate time string
    #findTimeString = () => {
        let minutes = String(Math.trunc(this.countDownTime / 60));
        let seconds = String(this.countDownTime % 60);
        if (minutes.length === 1) {
            minutes = "0" + minutes;
        }
        if (seconds.length === 1) {
            seconds = "0" + seconds;
        }
        return minutes + seconds;
    };

// Function to start Countdown
    startTimer = () => {
        if (this.isStopped) {
            this.isStopped = false;
            this.timerID = setInterval(this.#runCountDown, 1000);
        }
    };

// Function to stop Countdown
    stopTimer = () => {
        this.isStopped = true;
        if (this.timerID) {
            clearInterval(this.timerID);
        }
    };

// Function to reset Countdown
    resetTimer = () => {
        this.stopTimer();
        this.countDownTime = this.defaultValue;
        //this.renderTime();
    };

// Attach onclick event to buttons
//     startAction.onclick = startTimer;
//     resetAction.onclick = resetTimer;
//     stopAction.onclick = stopTimer;

// Function to display coundown on screen
     renderTime = () => {
         const time = this.#findTimeString();
         this.min = time.slice(0,2)
         this.sec = time.slice(2,4)
     };

// function to execute timer
    #runCountDown = () => {
        // decement time
        this.countDownTime -= 1;
        //Display updated time
        this.renderTime();
        // timeout on zero
        if (this.countDownTime === 0) {
            this.stopTimer();
            // Play alarm on timeout
            this.countDownTime = this.defaultValue;
        }
    };

}