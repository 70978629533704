import React from 'react';
import './instruction.scss'
import video from '../../assets/video/gpt-write.mp4'

const Instruction = () => {
    return (
        <div>
            <div className="dashboardContainer">
                <h3>How to use</h3>
                <div className="video">
                    <div>Click where you can write text on any site. Write a prompt and add //. at the end. <br/>For example, "write to Santa to give me a PS5//."<br/><br/><br/></div>
                    <video src={video} loop autoPlay muted controls type="video/mp4"></video>
                    <div>
                        <br/><br/>Enjoy!
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Instruction;