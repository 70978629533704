import React, {useEffect, useState} from 'react';
import Input from "../../utils/input";
import {auth, login} from "../../actions/user";
import {useDispatch, useSelector} from "react-redux";
import {userLogout} from "../../reducers/userReducer";
import './login.scss'
import logo from '../../assets/img/behind-logo.svg'
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../utils/loader/loader";



const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.user.isAuth)
    const user = useSelector(state => state.user.currentUser.email)

    const [loader, setLoader] = useState('')

    useEffect(() => {
        document.body.classList.add('bg');
        return () => {
            document.body.classList.remove('bg');
        };
    }, []);

    useEffect(() => {
        dispatch(auth())
    }, [])

    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth) {
            navigate("/");
        }
    }, [user]);

    const handleSubmit = e => {
        e.preventDefault();
        setLoader(<Loader/>)
        dispatch(login(email,password))
    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key

        if (e.keyCode === 13) {
            handleSubmit();
        }
    };

    return (
        <div className="loginScreen">
            <div className='loginLogo'>
                <Link to="/"><img src={logo}/></Link>
            </div>
            <div className="loginContainer">
                {!isAuth && <form>
                    <div className="loginHeader">Sign In</div>
                    <div className="loginInputContainer">
                        <Input className="loginInput" value={email} setValue={setEmail} type="email" placeholder="Your email"/>
                    </div>
                    <div className="loginInputContainer">
                        <Input className="loginInput" value={password} setValue={setPassword} onKeyPress={handleKeypress} type="password" placeholder="Your password"/>
                    </div>
                    <div className="loginInputContainer">
                        <button className="loginButton" onClick={handleSubmit} type="submit" >{loader}Sign in</button>
                    </div>
                    <div className="formAfterMessage">
                        Don't have an account? <a href="/signup">Sign up</a>
                    </div>
                </form>}

                {isAuth && <div><button onClick={()=>dispatch(userLogout())}>Logout</button></div>}

            </div>

        </div>
    );
};

export default Login;