import React, {useEffect, useRef, useState} from 'react';
import './productivity.scss'
import Navbar from "../navbar/navbar";
import {useDispatch, useSelector} from "react-redux";
import {records, setCustomRecords, setRecords} from "../../actions/record";
import dayjs from "dayjs";
import {Box, Rating} from "@mui/material";
import { Modal } from '@mui/material';
import {redirect, useNavigate, Link} from "react-router-dom";
import {GridRowsProp} from "@mui/x-data-grid";
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';

import FocusCounter from "../../services/counter";
import {auth} from "../../actions/user";
import Footer from "../footer/footer";
import Profile from "../profile/profile";
import Download from "../download/download";
import Instruction from "../instruction/instruction";

const Productivity = () => {

    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.user.isAuth)
    const user = useSelector(state => state.user.currentUser.email)
    const role = useSelector(state => state.user.currentUser.role)
    const ext = useSelector(state => state.user.extToken)


    const recordData = useSelector(state => state.records.records)


    const userLang = navigator.language || navigator.userLanguage;


    async function recieveData() {
        await dispatch(auth())
        await dispatch(records())

    }

    useEffect(() => {
        recieveData()
    }, []);

    useEffect(() => {
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };
    }, []);

    let reci
    const chartLabels = recordData.map((data) => {
            if (reci != dayjs(data.date).format('D.MM'))
                {
                    reci = dayjs(data.date).format('D.MM')
                    return dayjs(data.date).format('MM/D')
                }
            return ""
        }
    )



    const chartDataProductivity = recordData.map((data, index) => {
        return data.labels.productivity
    })

    const chartDataEnergy = recordData.map((data, index) => {
        return data.labels.energy
    })

    const [ratingProductivityValue, setProductivityRatingValue] = useState(5)
    const [ratingEnergyValue, setEnergyRatingValue] = useState(7)

    const [modalOpen, setModalOpen] = useState(false)

    const ifCounterStop = (state) => {
        setModalOpen(state)
    }


    const [noiseState, setNoiseSate] = useState(true)


    const noiseFromCounter = (state) => {
        setNoiseSate(state)
    }


    function modalHandleClose() {
        setModalOpen(false)
    }

    async function sendDateAndClose() {
        await dispatch(auth())
        const handle = await setRecords(ratingProductivityValue,ratingEnergyValue,noiseState)
        //use effect on click
        if (handle == "Record added") {
            setModalOpen(false)
        }

    }

    const lab = {
        coffee: true,
        trainings: true,
        carbs: "Jam",
        mood: 10
    }

    async function sendCustomData(lab) {
        const handle = await setCustomRecords(lab)
    }

    //sendCustomData(lab)



    useEffect(() => {
        dispatch(records())
    }, [modalOpen])



    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        //link.href = 'https://stackoverflow.com/favicon.ico';
    }, []);


    const rows = (data) => {
        const arr = []
        let mapDate
        let arrDate
        //for (let key=Object.keys(data).length-1; key>-1; key--)
        for (let key=0; key<Object.keys(data).length; key++) {
            if (dayjs(mapDate).format('DD') != dayjs(data?.[key]?.date).format('DD')) {
                arrDate = dayjs(data?.[key]?.date).format(' dd, MMM DD, YYYY')
                mapDate = data?.[key]?.date
            } else {
                arrDate = ''
            }
            arr.push(
                {
                    id : key,
                    //col1 : dayjs(data?.[key]?.date).format('DD MMM YY dd'),
                    col1 : arrDate,
                    col2 : data?.[key]?.date,
                    col3 : data?.[key]?.labels?.noise,
                    col4 : data?.[key]?.labels?.energy,
                    col5 : data?.[key]?.labels?.productivity,
                }
            )
        }
        //console.log(typeof(arr))
        return arr

    }

    const rows1: GridRowsProp = [
        { id: 1, col1: 'Hello', col2: 'World' },
        { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
        { id: 3, col1: 'MUI', col2: 'is Amazing' },
    ];
    //console.log(rows(recordData))
    //console.log(rows1)


    ///


    Array.prototype.unique = function() {
        var a = this.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }

        return a;
    };

    function getKeys (data) {
        let arr = []
        let result = [{field: 'col1', headerName: 'Date', width: 220}]
        for (const key in data) {
            if (data?.[key]?.labels) {
                //console.log(Object.keys(data?.[key]?.labels))
                arr = arr.concat(Object.keys(data?.[key]?.labels)).unique()
            }
        }

        arr.map((data, index) => [
                result.push(
                    {
                        field: 'col'+(index+2),
                        headerName: data,
                        minWidth: 150,
                    }
                )
            ]

        )
        //console.log(result)
        return result
    }

    //{ field: 'col1', headerName: 'Date', width: 220 },

    //console.log('keys'+ getKeys(recordData))
   // console.log('data' +rows(recordData))




    const columns = getKeys(recordData)
    //console.log(columns)

    const counter = new FocusCounter()

    counter.startTimer()


    const [chartWidth, setChartWidth] = useState('1160px')
    const rightRef = useRef({scrollLeft:0})
    function chartSize () {
        const length = Object.keys(chartLabels).length
        if(length > 20) {
            let newWidth = 1160 + length*20
            setChartWidth(`${newWidth}px`)

            if (rightRef.current) {
                rightRef.current.scrollLeft += newWidth
            }

        } else {setChartWidth('1160px')}
    }


    useEffect(() => {
        chartSize()
    }, [rows]);

    const isCounterSize = useSelector(state => state.appearance.counterFull)

    const navigate = useNavigate();

    const [extKey, setExtKey] = useState('null')

    useEffect(() => {
        if (!isAuth) {
            navigate("");
        }
        setExtKey(ext)
    }, [user]);

    useEffect(() => {
        setExtKey(ext)
    }, [ext]);



    const recount = useRef(0);

    useEffect(() => {
        recount.current = recount.current + 1;
    });

   //console.log("User: " + user + "\nRole: "+role+"\nAuth: " + isAuth + "\nRerender: " + recount.current);

    return (
        <div className="productivity">
            <Navbar/>
            <input type='hidden' id='behind_ext'value={extKey}></input>
            {isAuth ?
                <div>


                    <Profile />
                    <div>
                        <Modal
                            open={modalOpen}
                            //onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                        >
                            <Box
                                style={{padding: '50px 40px', borderRadius: '12px'}}
                                sx={{
                                    width: 500,
                                    height: 300,
                                    backgroundColor: 'rgba(255,255,255,1)',
                                }}>
                                <div>
                                    <div className="modalHeader">
                                        How did your activity go?
                                    </div>
                                    <div className="modalSubHeader">
                                        Energy
                                    </div>
                                    <Rating
                                        name="simple-controlled"
                                        max={10}
                                        size="large"
                                        value={ratingEnergyValue}
                                        icon={<FavoriteIcon fontSize="inherit" style={{color: "#98a6d5"}} />}
                                        emptyIcon={<FavoriteIcon fontSize="inherit" style={{color: "rgba(155,166,213,0.2)"}}/>}
                                        onChange={(event, newRatingValue) => {
                                            setEnergyRatingValue(newRatingValue);
                                        }}
                                    />
                                    <div className="modalSubHeader">
                                        Productivity
                                    </div>
                                    <Rating
                                        name="simple-controlled"
                                        max={10}
                                        size="large"
                                        value={ratingProductivityValue}
                                        icon={<StarIcon fontSize="inherit" style={{color: "#3d429a"}} />}
                                        emptyIcon={<StarIcon fontSize="inherit" style={{color: "rgba(61,66,154,.2)"}}/>}
                                        onChange={(event, newRatingValue) => {
                                            setProductivityRatingValue(newRatingValue);
                                        }}
                                    />

                                    <div className="modalControl">
                                        <button className="modalSubmit" onClick={sendDateAndClose}>Save</button>
                                        <a className="modalLinkButton" href="#" onClick={modalHandleClose}>Cancel</a>
                                    </div>

                                </div>
                            </Box>
                        </Modal>

                    </div>

                </div>


                :
                <div className='dashboardContainer'>
                    <div className="welcomeAction">
                        <div className="loginContainer">
                            <div className="loginHeader">Create an account or login to connect your extension</div>
                            <div className="loginInputContainer">
                                <Link className="loginButton" to="/signup">Sign up</Link>
                            </div>

                            <div className="loginInputContainer formOr"><span>-or-</span></div>
                            <div className="loginInputContainer" style={{textAlign: "center", paddingTop: 7}}><Link className="loginLink" to="/login">Login</Link></div>
                        </div>
                    </div>
                </div>
            }

            <Download />
            <Instruction />

            <Footer />

        </div>
    );
};

export default Productivity;