import React from 'react';
import './loader.scss'
import loader from '../../assets/img/tail-spin.svg'
const Loader = () => {

    return (
        <div className="loader-container">

        </div>
    );
};

export default Loader;