const SET_THEME = "SET_THEME"
const SET_COUNTER = "SET_COUNTER"

const defaultState = {
    theme: "white",
    counterFull: true

}

export default function appearanceReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                theme: action.payload
            }
        case SET_COUNTER:
            return {
                ...state,
                counterFull: action.payload
            }
        default:
            return state
    }
}

export const setTheme = (theme) => ({type: SET_THEME, payload: theme})
export const setCounter = (counter) => ({type: SET_COUNTER, payload: counter})