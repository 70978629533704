import React from 'react';
import logo from "../../assets/img/behind-logo.svg";
import './footer.scss'

const Footer = () => {
    return (
        <div className="footer">
            <div className="dashboardContainer">
                <div className="footerContainer">
                    <div className="footerLogo"><img src={logo}/></div>
                    <div className="allRights">
                        Copyright © 2023 Behind.ai. All rights reserved.
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;