import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {store} from "./reducers"
import {Provider} from "react-redux";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Signup from "./components/signup/signup";
import Login from "./components/login/login";
import Profile from "./components/profile/profile";
import Productivity from "./components/productivity/productivity";
import { createBrowserHistory } from 'history';
import Checkout from "./components/checkout/checkout";


const root = ReactDOM.createRoot(document.getElementById('root'));
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
// We listen to the resize event
// window.addEventListener('resize', () => {
//     // We execute the same script as before
//     let vh = window.innerHeight * 0.01;
//     document.documentElement.style.setProperty('--vh', `${vh}px`);
// });
root.render(
    <CookiesProvider>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />}>

                    </Route>
                    <Route path="signup" element={<Signup />} />
                    <Route path="login" element={<Login />} />
                    {/*<Route path="checkout" element={<Checkout />} />*/}

                </Routes>
            </BrowserRouter>

        </Provider>
    </CookiesProvider>
);
