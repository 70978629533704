import axios from 'axios'
import {setExtToken, setUser, userLogout} from "../reducers/userReducer";
import {redirect} from "react-router-dom";
import {serverURL, serverURL_payments} from "../config";
import {getRecords} from "../reducers/userDataReducer";

export const signup = async (email, password) => {
    try {
        const response = await axios.post(`${serverURL}/api/auth/signup`, {
            email,
            password
        },{withCredentials: true})
        return response.data.message
    } catch (e) {
        alert(e.response.data.message)
    }
}

export const login = (email, password) => {
    return async dispatch => {
        try {
            const response = await axios.post(`${serverURL}/api/auth/login`, {
                email,
                password
            },{withCredentials: true})
            dispatch(setUser(response.data.user))
            localStorage.setItem('token', response.data.tokens.accessToken)
        } catch (e) {
            alert(e.response.data.message)
        }
    }
}

export const subscribe = async (priceId) => {
        try {
            const response = await axios.post(`${serverURL_payments}/create-checkout-session`, {
                priceId
            }, {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}})

            return(response.data.message)
        } catch (e) {
            alert(e.response.data.message)
        }
}
export const manageSubscription = async () => {
    try {
        const response = await axios.post(`${serverURL_payments}/create-portal-session`, {}
        , {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}})

        return(response.data.message)
    } catch (e) {
        alert(e.response.data.message)
    }
}
export const auth_1 =  () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${serverURL}/api/auth/auth`,
                {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}}
            )
            dispatch(setUser(response.data.user))
        } catch (e) {
            localStorage.removeItem('token')
        }
    }
}
export const auth =  () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${serverURL}/api/auth/refresh`,
                {withCredentials: true}
            )
            if (response.data.user) {
                dispatch(setUser(response.data.user))
                dispatch(setExtToken(response.data.tokens.accessToken))
                localStorage.setItem('token', response.data.tokens.accessToken)
            }

        } catch (e) {
            localStorage.removeItem('token')
        }
    }
}
export const logout =  () => {
    return async dispatch => {
        try {
            const response = await axios.post(`${serverURL}/api/auth/logout`,{},{withCredentials: true})
            dispatch(userLogout())
        } catch (e) {
            localStorage.removeItem('token')
        }
    }
}