const GET_RECORDS = "GET_RECORDS"
const defaultState = {
    records: [],

}

export default function userDataReducer(state = defaultState, action) {
    switch (action.type) {
        case GET_RECORDS:
            return {
                ...state,
                records: action.payload
            }
        default:
            return state
    }
}

export const getRecords = (records) => ({type: GET_RECORDS, payload: records})