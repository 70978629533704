import React from 'react';
import chrome from '../../assets/img/chrome.png'
import './download.scss'
import {extensionURL} from "../../config";

const Download = () => {
    return (
        <div id="behind-gptext-download">
            <div className="dashboardContainer">
                <h3>Download extension</h3>
                <div className='downloadContainer'>
                    <p>Download the extension to start using GPText on any website:</p>
                    <div>
                        <a href={extensionURL}><img className='downloadImage' src={chrome}/> <div>
                            Download extension
                        </div></a>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default Download;