import React, {useEffect, useState} from 'react';
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import {useDispatch, useSelector} from "react-redux";
import {auth, logout, subscribe, manageSubscription} from "../../actions/user";
import dayjs from "dayjs";
import {counterSize} from "../../actions/appearance";
import LoginRounded from "@mui/icons-material/LoginRounded";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import './profile.scss'
import {extensionURL} from "../../config";

const Profile = () => {

    const dispatch = useDispatch()

    const user = useSelector(state => state.user.currentUser.email)
    const role = useSelector(state => state.user.currentUser.role)
    const expires = useSelector(state => state.user.currentUser.expires)

    const isAuth = useSelector(state => state.user.isAuth)

    const [emailValue, setEmailValue] = useState('Retrieving...')
    const [roleValue, setRoleValue] = useState('Retrieving...')
    const [expireslValue, setExpireslValue] = useState('Retrieving...')


    async function retrieveUser() {
        await dispatch(auth())
        setEmailValue(user)
        setRoleValue(role=="paid"?"Premium":role)
        setExpireslValue(dayjs(expires).format('MMM DD, YYYY HH:mm'))
    }

    async function goToPay() {
        const handle = await subscribe('price_1Ma7yzCQ8BQLZWzXH3Jg5r3J')
        //const handle = await subscribe('price_1Ma90yCQ8BQLZWzX7Hsu1yEJ')

        if (handle) {
            window.location.href = handle;
        }

    }
    async function goToManage() {
        const handle = await manageSubscription()
        if (handle) {
            window.location.href = handle;
        }

    }
    useEffect(() => {
        dispatch(counterSize(false))
        document.body.classList.add('mg');
        return () => {
            document.body.classList.remove('mg');
        };
    }, []);


    useEffect(() => {
        retrieveUser()
    }, [user]);

    useEffect(() => {
        retrieveUser()
    }, [role]);


    return (
        <div>
            <div className="dashboardContainer">
                <h3>Profile</h3>
                <div className="profileCenter">
                    <div className="profileString">
                        <label>Email:</label>
                        <div className="profileStringValue">&nbsp;{emailValue}</div>
                    </div>
                    <div className="profileString">
                        <label>Account type:</label>
                        <div className="profileStringValue">&nbsp;{roleValue}&nbsp;</div>
                        <div className="profileUpgrade">
                            <a href='#' onClick={goToPay} style={{display:roleValue==='unverified'||roleValue==='free'?'inline-block':'none'}}>Upgrade</a>
                        </div>
                    </div>
                    <div className="profileString">
                        <label>Account expires:</label>
                        <div className="profileStringValue">&nbsp;{expireslValue}</div>
                    </div>
                    <div className="profileString">
                        <label>Extension status:</label>
                        <div className="profileStringValue" id='behind-ext-status'>&nbsp;Disconnected <a href={extensionURL}>Download extension</a></div>
                    </div>
                    <div className="profileString">
                        <a href='#' onClick={goToManage} className="profileManage" style={{display:role==='paid'?'inline-block':'none'}}>Manage subscription</a><Link to='/' className="profileLogout" href="#" onClick={()=>dispatch(logout())}><span className="userName">Logout</span> <span>{<LoginRounded className="loginRounded"/>}</span></Link>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Profile;