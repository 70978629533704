import {Router, Route, Routes, Link, Outlet} from "react-router-dom";
import Navbar from "./navbar/navbar";
import Signup from "./signup/signup";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";

import {auth, signup} from "../actions/user";
import "./app.scss"
import Productivity from "./productivity/productivity";
import ReactGA from 'react-ga';


function App() {
    const isAuth = useSelector(state => state.user.isAuth)


    const dispatch = useDispatch()

    useEffect(() => {
        document.body.classList.add('bg');
        return () => {
            document.body.classList.remove('bg');
        };
    }, []);

    useEffect(() => {
        dispatch(auth())
    }, [])

    const TRACKING_ID = "G-872YEYN7PC";
    ReactGA.initialize(TRACKING_ID);

  return (
              <div>
                  <Productivity />
                  {/*{!isAuth ?*/}


                  {/*    <div className="loginScreen">*/}
                  {/*        <div className="welcomeContainer">*/}
                  {/*            <Link className="loginLogo" to="/"><img src={logo}/></Link>*/}
                  {/*            <div className="welcome">*/}
                  {/*                <div className="welcomeHeader">*/}
                  {/*                    <div>Welcome on board!</div>*/}
                  {/*                    <p>A flight mode-simulating app</p>*/}
                  {/*                    <span>Use it to: </span>*/}
                  {/*                </div>*/}
                  {/*                <div className="welcomeActionContainer">*/}
                  {/*                  <div className="welcomeFeatures">*/}
                  {/*                      <div className="welcomeFeature">*/}
                  {/*                          <div className="welcomeFeature-img">*/}
                  {/*                              <img src={compass}/>*/}
                  {/*                          </div>*/}
                  {/*                          <div className="welcomeFeature-content">*/}
                  {/*                              <div className="welcomeFeatureHeader">*/}
                  {/*                                  Stay focused*/}
                  {/*                              </div>*/}
                  {/*                              <div className="welcomeFeatureText">*/}
                  {/*                                  It improves focus on tasks*/}
                  {/*                              </div>*/}
                  {/*                          </div>*/}
                  {/*                      </div>*/}
                  {/*                      <div className="welcomeFeature">*/}
                  {/*                          <div className="welcomeFeature-img">*/}
                  {/*                              <img src={planeUp}/>*/}
                  {/*                          </div>*/}
                  {/*                          <div className="welcomeFeature-content">*/}
                  {/*                              <div className="welcomeFeatureHeader">*/}
                  {/*                                  Be productive*/}
                  {/*                              </div>*/}
                  {/*                              <div className="welcomeFeatureText">*/}
                  {/*                                  It increases productivity when you work on the computer*/}
                  {/*                              </div>*/}
                  {/*                          </div>*/}
                  {/*                      </div>*/}
                  {/*                      <div className="welcomeFeature">*/}
                  {/*                          <div className="welcomeFeature-img">*/}
                  {/*                              <img src={planeDown}/>*/}
                  {/*                          </div>*/}
                  {/*                          <div className="welcomeFeature-content">*/}
                  {/*                              <div className="welcomeFeatureHeader">*/}
                  {/*                                  Calm your brain*/}
                  {/*                              </div>*/}
                  {/*                              <div className="welcomeFeatureText">*/}
                  {/*                                  It helps people with ADHD*/}
                  {/*                              </div>*/}
                  {/*                          </div>*/}
                  {/*                      </div>*/}
                  {/*                      <div className="welcomeFeature">*/}
                  {/*                          <div className="welcomeFeature-img">*/}
                  {/*                              <img src={passenger}/>*/}
                  {/*                          </div>*/}
                  {/*                          <div className="welcomeFeature-content">*/}
                  {/*                              <div className="welcomeFeatureHeader" style={{fontSize: 18}}>*/}
                  {/*                                  Be relaxed and productive!*/}
                  {/*                              </div>*/}
                  {/*                          </div>*/}
                  {/*                      </div>*/}
                  {/*                  </div>*/}
                  {/*                    <div className="welcomeDivider"></div>*/}
                  {/*                  <div className="welcomeAction">*/}
                  {/*                      <div className="loginContainer">*/}
                  {/*                          <div className="loginHeader">Create an account or sign in</div>*/}
                  {/*                          <div className="loginInputContainer">*/}
                  {/*                              <Link className="loginButton" to="/signup">Sign up</Link>*/}
                  {/*                          </div>*/}

                  {/*                          <div className="loginInputContainer formOr"><span>-or-</span></div>*/}
                  {/*                          <div className="loginInputContainer" style={{textAlign: "center", paddingTop: 7}}><Link className="loginLink" to="/login">Login</Link></div>*/}
                  {/*                      </div>*/}
                  {/*                  </div>*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*    :*/}
                  {/*    <Productivity />*/}
                  {/*}*/}
              </div>
  );
}

export default App;
