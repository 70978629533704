import {applyMiddleware, combineReducers, createStore} from "redux"
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk"
import userReducer from "./userReducer";
import userDataReducer from "./userDataReducer";
import appearanceReducer from './appearanceReducer'

const rootReducer = combineReducers({
    user: userReducer,
    records: userDataReducer,
    appearance: appearanceReducer
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))