import React, {useEffect, useState} from 'react';
import './navbar.scss'
import logo from '../../assets/img/behind-logo.svg'
import logoW from '../../assets/img/behind-logo.svg'
import {useDispatch, useSelector} from "react-redux";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";


const Navbar = () => {
    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.user.isAuth)
    const user = useSelector(state => state.user.currentUser.email)

    const [cookies, setCookie] = useCookies(["user"])

    function handleCookie() {
        setCookie("initialTutorial", "true", {
            path: "/",
            expires: new Date(Date.now()+(3*30*24*60*60*1000))
        });
    }

    const [modalOpen, setModalOpen] = useState(false)

    const onModalOpen = () => {
        setModalOpen(true)
    }
    const onModalClose = () => {
        setModalOpen(false)
        modalSlideSwitcher()
        handleCookie()
    }

    const [modalWelcome, setModalWelcome] = useState('block')
    const [modalHow, setModalHow] = useState('none')

    function modalSlideSwitcher() {
        if (modalWelcome == 'block') {
            setModalWelcome('none')
            setModalHow('block')
        } else {
            setModalWelcome('block')
            setModalHow('none')
        }
    }

    useEffect(() => {

        if (cookies.initialTutorial == 'false' || cookies.initialTutorial == undefined) {
            //onModalOpen()
        }
    }, [])

    const isCounterSize = useSelector(state => state.appearance.counterFull)

    return (
        <div className={isCounterSize ? 'navbar navbarWhite' : 'navbar'}>
            <div className='dashboardContainer'>
                <div className="navbar-left">
                    <Link className='navbarLogo' to="/">
                        <img src={isCounterSize ? logoW : logo}/>
                    </Link>
                    {/*<div onClick={onModalOpen} className='howTo'>How to use?</div>*/}
                </div>

                {/*<div className="navbar-right">{isAuth && <div className="navbarLogout"><Link to="/profile" href="#"><span className="userName">{user}</span></Link></div>}</div>*/}
            </div>

        </div>
    );
};

export default Navbar;