import React, {useEffect, useState} from 'react';
import Input from "../../utils/input";
import {auth, login, signup} from "../../actions/user";
import './signup.scss'
import logo from "../../assets/img/behind-logo.svg";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Loader from "../../utils/loader/loader";


const Signup = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [response, setResponse] = useState("")
    const [loader, setLoader] = useState('')
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.user.isAuth)
    //const user = useSelector(state => state.user.currentUser.email)

    useEffect(() => {
        dispatch(auth())
    }, [])

    useEffect(() => {
        if (isAuth) {
            navigate("/");
        }
    }, [isAuth]);

    const signingUp = e => {
        e.preventDefault()

        setLoader(<Loader/>)
        const res = signup(email,password)
        res.then((result) => {
            setResponse(result)
            setLoader('')
            dispatch(login(email,password))
        })
    }


    const handleKeypress = e => {
        //it triggers by pressing the enter key

        if (e.keyCode === 13) {
            signingUp();
        }
    };

    useEffect(() => {
        document.body.classList.add('bg');
        return () => {
            document.body.classList.remove('bg');
        };
    }, []);

    return (
        <div className="loginScreen">
            <div className='loginLogo'>
                <Link to="/"><img src={logo}/></Link>
            </div>
            <div className="loginContainer"><form>
                <div className="loginHeader">Sign Up</div>
                <div>{response}</div>
                <div className="loginInputContainer">
                    <Input value={email} setValue={setEmail} type="text" placeholder="Your email"/>
                </div>
                <div className="loginInputContainer">
                    <Input value={password} setValue={setPassword} onKeyPress={handleKeypress} type="password" placeholder="Create password"/>
                </div>
                <div className="loginInputContainer">
                    <button className="loginButton" onClick={signingUp}>{loader}Sign up </button>
                </div>
                <div className="formAfterMessage">
                    Already have an account? <a href="/login">Sign in</a>
                </div></form>
            </div>
        </div>
    );
};

export default Signup;